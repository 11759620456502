	#portal-appbar {
		height:60px;
		width:100%;
		background-color: #37a2db;
		position: fixed;
		z-index: 1040;
		#nav {
			padding:18px 24px;
			font-size: 24px;
			line-height: 1;
			float: left;
		}
		#logo {
			width:150px;
			float: left;
			padding:10px 0px;
			&:hover {
				opacity: 0.8;
    			filter: alpha(opacity=80);
			}
		}
		videogular vg-controls .controls-container vg-play-pause-button .iconButton, videogular vg-controls .controls-container .iconButton, {
		  	padding: 9px 3px 9px 3px;
		}


		.support-question-mark {
			margin-right: 0px;
			padding: 22px 8px 22px 0px;
			font-size: 14px;
			line-height: 1;
			float: right;
		}
		.md-account-circle {
			margin-right: 0px;
			padding: 18px 6px 18px 6px;
			font-size: 24px;
			line-height: 1;
			float: right;
		}
		#messages {
			float: right;
			cursor: pointer;
			.md-chat {
				padding:18px 6px 18px 6px;
				font-size: 24px;
				line-height: 1;
				float: right;
			}
			#new-messages {
				margin-left: 12px;
				height:16px;
				width:auto;
				margin-top:22px;
				margin-right: 4px;
				padding: 1px 6px;
				-webkit-border-radius: 8px;
				-moz-border-radius: 8px;
				border-radius: 8px;
				font-size: 10px;
				float: right;
				display: inline-block;
			}
		}
		.appbar-icons {
			padding: 18px 6px 18px 6px;
			font-size: 24px;
			line-height: 1;
			float: right;
		}
	}
	#toolbar {
		position: fixed;
		top:60px;
		height:48px;
		width:100%;
		background-color: white;
		-webkit-box-shadow: 0px -5px 15px 6px rgba(0,0,0,0.25);
		-moz-box-shadow: 0px -5px 15px 6px rgba(0,0,0,0.25);
		box-shadow: 0px -5px 15px 6px rgba(0,0,0,0.25);
		z-index: 1039;
		.md-settings, .md-search, .md-today, .md-add-circle, .md-poll{
			padding:12px;
			font-size: 24px;
			line-height: 1;
			float: right;
		}
		.md-settings{
			margin-right: 12px;
		}
	}
	#portal-content{
		top:60px;
		width: 100%;
		//overflow-x: hidden;
		position: absolute;
		padding: 16px;
		max-width: 1350px;
		width: 100%;
		margin-left: auto;
		margin-right: auto;
		left: 0;
		right: 0;
	}




@media (max-width: 400px){
	#portal-appbar {
		height:40px;
		#nav {
			padding:8px 8px;
			line-height: 1;
			width: 41px !important;
		}
		#logo {
		  	width: 110px;
			float: left;
			padding: 5px 0px;
		}
		.support-question-mark {
			padding: 12px 4px 12px 0px;
		}
		.md-account-circle {
			margin-right: 0px;
			padding: 8px 8px 8px 8px;
		}
		#messages {
			.md-chat {
				padding: 8px 2px 8px 2px;
			}
			#new-messages {
				margin-top:12px;
			}
		}
		.appbar-icons {
			padding: 8px 2px 8px 2px;
		}

	}
	#toolbar {
		top:40px;
		height:38px;
		.md-settings, .md-search, .md-today, .md-add-circle{
			padding:8px;
			font-size: 24px;
			line-height: 1;
			float: right;
		}
		.md-settings{
			margin-right: 0px;
		}
	}
	#portal-content{
		top: 40px;
		padding: 8px;
	}

}
