#portal-content-wide {
  top: 60px;
  width: 100%;
  overflow-x: hidden;
  position: absolute;
  padding: 16px;
  max-width: 1680px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
}

@media (max-width: 400px) {
  #portal-content-wide {
    top: 40px;
    padding: 8px;
  }

}

videogular vg-scrub-bar [role=slider], [videogular] vg-scrub-bar [role=slider] {
  margin-top: -6px;
  margin-bottom: 0px;
  background-color: rgba(125, 125, 125, .5);
}

videogular vg-controls .controls-container, [videogular] vg-controls .controls-container {
  -webkit-box-shadow: none;
  box-shadow: none;
  transition: all ease .25s;
  -moz-transition: all ease .25s;
  -webkit-transition: all ease .25s;
}

.controls-container {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

videogular vg-controls .controls-container vg-scrub-bar [role=slider] {
  background-color: rgba(125, 125, 125, .5);

}

videogular vg-controls .controls-container vg-play-pause-button .iconButton, videogular vg-controls .controls-container .iconButton, {
  padding: 9px 9px 9px 9px;
  &:focus {
    outline: none;
  }
}

@media (max-width: 767px) {
  #event-info-download {
    padding-left: 0px;
  }

  videogular vg-controls .controls-container vg-play-pause-button .iconButton, videogular vg-controls .controls-container .iconButton, {
    padding: 9px 3px 9px 3px;
  }
}

.comment-cue {
  position: absolute;
  max-width: 250px;
  background-color: rgba(0, 0, 0, 0.8);
  font-size: 10px;
  padding: 10px;
  color: #ffffff;
  width: 400px;
  text-align: center;
  bottom: 20px;

}

videogular vg-buffering, [videogular] vg-buffering {
  z-index: 1030;
}

#cue-points {
  height: 8px;
  margin-top: -5px;
  width: 100%;
  position: relative;
  #cue-point {
    position: absolute;
    height: 8px;
    display: inline-block;
    .cue-point-marker {
      width: 8px;
      height: 8px;
      border: 1px solid white;
      background-color: rgba(255, 255, 255, 0.3);
      -webkit-border-radius: 15px;
      -moz-border-radius: 15px;
      border-radius: 15px;
      right: 0px;
      margin-left: -8px;
      position: absolute;
      &:hover {
        height: 10px;
        width: 10px;
        margin-top: -1px;
        margin-left: -10px;
      }
    }
  }
}

videogular, [videogular] {
  overflow: hidden;
}

vg-controls > .controls-container {
  -webkit-transition: bottom .25s ease;
  -moz-transition: bottom .25s ease;
  -ms-transition: bottom .25s ease;
  -o-transition: bottom .25s ease;
  transition: bottom .25s ease;
  position:absolute;
  bottom:0;
}

vg-controls.special-hide > .controls-container {
  bottom:-50px;
}
